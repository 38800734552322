import React, { Component } from 'react';
import './equipe.scss';
import CarouselItem from '../bloc-components/carousel-item';
import Header from '../bloc-components/header-component';
/** Module pour carousel responsive */
// https://www.npmjs.com/package/react-responsive-carousel
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Equipe extends Component {
    render() {
        let marc = require('../../images/equipe/marc.png');
        let julien = require('../../images/equipe/julien.png');
        return (
            <div id="equipe" className="flex-container column-flex">
                <Header subtitle="Notre équipe de management" />
                <div className="carousel-container">
                    <Carousel showThumbs={false} showStatus={false} useKeyboardArrows >
                        <CarouselItem url={marc} title="Marc" subtitle="Fondateur président" description="Marc travaille dans le domaine des bases de données depuis plus de 15 ans et a développé une véritable culture orientée client. Après une experience de consultant technique puis de directeur d’unité opérationnelle, il fait le constat que l’évolution des solutions IT nécessite une grande agilité organisationnelle, et décide de créer NeoDT en 2015.
Son objectif: Etre au plus près des besoins des clients en apportant une expertise constante dans le domaine de la DATA. Marc est également certifié Oracle OCP 10g, OCP 11g, Expert Performance Tuning, Data Warehousing et Exadata." />
                        <CarouselItem url={julien} title="Julien" subtitle="Responsable technique" description="Julien est un expert des bases de données et travaille depuis plus de 10 ans en environnement de production. Son rôle chez NeoDT : participer au développement des offres (Consulting, Services managés, Formations) et piloter les ressources techniques. Julien opère également au niveau d'architectures complexes et revendique plus de 10 implémentations de solutions EXADATA. Il accompagne également la stratégie Open Source et NoSQL de l’entreprise." reverse="true" />
                    </Carousel>
                </div></div>
        )
    }
}
