import React, { Component } from 'react';
import "./header.scss";

/**
 * Bloc header SPA
 * @param {string} theme - 'dark' ou 'light'
 */
export default class Header extends Component {
    render() {
        return (
            <div className={this.props.theme === "dark" ? "dark" : "light"}>
                <h3>{this.props.subtitle}</h3>
                <h1>{this.props.title}</h1>
            </div>
        )
    }
}
