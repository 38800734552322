import React, { Component } from 'react';
/** Composants d'un block */
import Header from '../bloc-components/header-component';
import Flipcard from '../bloc-components/flip-card';
import './partenariat.scss';
import { Slide } from 'react-awesome-reveal';

export default class Partenariat extends Component {
    constructor(props){
        super();
        this.state = {isFlipped: false};
        this.handleClick = this.handleClick.bind(this);
    }
      
    handleClick(event) {
      event.preventDefault();
      this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }
    render() {
        return (
            <div id="partenariat" className="column-flex flex-container">
                <Header theme="dark" subtitle="Nos partenaires" />
                <div className="flex-container row-flex cards-container">
                    <Slide cascade="0.2" direction="up" triggerOnce="1" fraction="0.5">
                        <Flipcard
                            title="Centreon"
                            logo="/partenaires/centreon"
                            link="https://www.centreon.com/societe/partenaires/"
                            description="Centreon est un éditeur de logiciels de supervision AIOps qui permet continuité de service et excellence opérationnelle."
                            details="Acteur de la supervision open source, Centreon nous permet de capitaliser à ses côtés afin d'établir un écosystème de partenariats dynamiques qui assure de la valeur à nos clients." />
                        <Flipcard
                            title="Oracle"
                            logo="/partenaires/oracle"
                            link="https://partner-finder.oracle.com/catalog/scr/Partner/SCPP-ZCJMZMBU.html"
                            description="Gage de son expertise et de son engagement auprès d’Oracle, NeoDT a obtenu en Octobre 2015 le statut GOLD PARTNER."
                            details="NeoDT a obtenu en Octobre 2015 le statut GOLD PARTNER. Ce statut donne à NeoDT un accès à tous les produits Oracle Technology, et rend éligible à la revente de produits Oracle Applications et Industry solutions. Grâce à ce statut, NeoDT dispose d’un accès direct aux représentants de comptes Oracle et aux mises à jour My Oracle Support pour tous les produits." />
                        <Flipcard
                            title="mongoDB"
                            logo="/partenaires/mongodb"
                            link="https://www.mongodb.com/partners/neodt"
                            description="NeoDT est partenaire MongoDB depuis son année de création en 2015."
                            details="Ce partenariat permet un accompagnement dans nos réflexions et de disposer d’informations relatives aux nouveautés MongoDB. Cela permet également de pouvoir bénéficier de l’expertise d’un Solution Architect accompagnant nos projets, et de définir ensemble les solutions techniques les mieux adaptées. " />
                    </Slide>
                </div>
            </div>
        )
    }
}
