import React, { Component } from 'react';
import Subcard from './sub-card';
/** styles */
import './descriptioncard.scss';
import { Zoom } from 'react-awesome-reveal';
import { Link } from "react-scroll";

/**
 * Carte avec icone, titre, description et bouton pour lire plus.
 * @param {hexadecimal} color - Couleur principale "red-colored", "blue-colored", "gold-colored" ou "green-colored"
 * @param {string} title - Titre principal
 * @param {string} details - Description de la carte
 * @param {string} icon - Icone au dessus du titre principal, on peut utiliser les fa fas uniquement via https://fontawesome.com/icons?d=gallery&m=free
 */
export default class Descriptioncard extends Component {
    constructor(props) {
        super();
        /** Récupération des données API */
        this.title = props.title;
        this.anchor = "description-card-"+this.title.toLowerCase().replace(' ', '-').replace('é','e');
        this.details = props.details;
        this.color = props.color;
        this.subs = props.serviceItem.subs;
        this.state = {
            show: false,
        }
        this.icon = null;
        if (props.logo) {
            let logo = require('../../../images/services/' + props.logo + '.png');
            this.icon = <img className="desc-card-img" src={logo} alt="Icone réalisée par Becris(voir footer)" title="Icons made by https://www.flaticon.com/authors/becris Becris" />
        }
    }
    /**
     * Permet de toggle le state d'affichage pour la subcard
     */
    showDetails = e => {
        this.setState({ show: !this.state.show });
    }

    componentDidMount() {
        if (this.subs !== undefined) {
            let subsList = Object.values(this.subs);
            this.setState({
                subsList: subsList.map((subItem) =>
                    <Subcard
                        key={subItem.key}
                        title={subItem.title}
                        description={subItem.description}
                        show={this.state.show}
                        color={this.color} />)
            })
        }
    }
    render() {
        /** Permet le conditionnal rendering en fonction de présence de subs le lien "lire plus" */
        let isSubs = this.subs !== undefined;
        let subsComponentList = this.state.subsList;
        return (
            <div id={this.anchor} className={"flex-container column-flex desc-card-container showed-" + this.state.show}>
                <div className={"main-card-container flex-container column-flex " + this.color}>
                    <div>
                        <Zoom triggerOnce="true" fraction="1" delay="40">
                            {this.icon}
                        </Zoom>
                        <h4>{this.title}</h4>
                        <p>{this.details}</p>
                    </div>
                    {
                        isSubs &&
                            this.state.show ? 
                            <Link to={this.anchor} smooth={true} duration={400} offset={-80}>
                                <span onClick={e => this.showDetails(e)}>lire moins<i className='fas fa-chevron-left'></i></span>
                            </Link>
                             :                     
                            <Link to={this.anchor} smooth={true} duration={400} offset={-80}>
                                <span onClick={e => this.showDetails(e)}>lire plus<i className='fas fa-chevron-right'></i></span>
                            </Link>
                    }
                </div>
                {
                    this.state.show ?

                        <div className={"flex-container column-flex sub-card-container showed-" + this.state.show}>
                            {subsComponentList}
                        </div>
                        : null
                }
            </div>
        )
    }
}
