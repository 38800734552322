import React, { Component } from 'react';
import { Fade } from 'react-awesome-reveal';
import "./carouselItem.scss";

/**
 * Un item du carousel en 2 parties avec Image et de l'autre côté texte
 * @param {boolean} reverse - Default false. Boolean qui représente le sens d'affichage, true = image à droite.
 * @param {string} title - Titre principal de la partie descriptive
 * @param {string} subtitle - Sous titre de la partie descriptive
 * @param {string} description - Description principale de la partie descriptive.
 * @param {string} url - URL de l'image de la partie image.
 */
export default class CarouselItem extends Component {
    render() {
        return (
            <div className={"carousel-item flex-container row-flex " + (this.props.reverse ? " reverse" : "")}>
                <div className="flex-container row-flex carousel-item-part img-part">
                    <div className="img-container">
                        <img src={this.props.url} alt="mage" />
                    </div>
                </div>
                <div className="flex-container column-flex carousel-item-part text-part">
                    <Fade triggerOnce="true" damping="0.5" cascade="true" fraction="0.5">
                        <h2>
                            {this.props.title}
                        </h2>
                        <h3>{this.props.subtitle}</h3>
                        <p>
                            {this.props.description}
                        </p>
                    </Fade>
                </div>
            </div>
        )
    }
}
