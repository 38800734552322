import React, { Component } from 'react'
import "./customercard.scss";

/**
 * Carte client avec simplement une icone et un lien au survol
 * @param {string} logo - Image de fond
 * @param {string} link - Lien vers le site 
 */
export default class Customercard extends Component {
    render() {
        this.item = this.props.item;
        this.image = null;
        if (this.item.logo) {
            let logo = require('../../../images/clients' + this.item.logo + '.png');
            this.image = <img src={logo} alt="logo" />
        }
        return (
            <div className="customer-card flex-container">
                {this.image}
                <a className="customer-link" href={this.item.link} target="_blank" rel="noopener noreferrer">Visiter leur site web</a>
            </div>
        )
    }
}
