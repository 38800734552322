import React, { Component } from 'react'
import Header from '../bloc-components/header-component';
import Customercard from '../bloc-components/customer-card';
import "./references.scss";
/** Fake API datas */
import customerDatasPlaceholder from "./customerPlaceholder.json";
import { Fade } from 'react-awesome-reveal';

export default class References extends Component {
    constructor(props) {
        super();
        let customerPlaceholderList = customerDatasPlaceholder;

        this.state = {
            isDesktop: false,
            customerList: Object.values(customerPlaceholderList),
            shortList: Object.values(customerPlaceholderList).filter(customer => customer.key > 6)
        }
        this.finalList = this.state.customerList.map((item) =>
            <Customercard key={item.key} item={item} />
        );
        this.shortList = this.state.shortList.map((item) =>
            <Customercard key={item.key} item={item} />
        )
        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 1450 });
    }
    render() {
        const isDesktop = this.state.isDesktop;
        return (
            <div id="references">
                <Header title="Ils nous font confiance" subtitle="Nos clients" />
                <div className="flex-container row-flex customer-cards-grid">
                    <Fade triggerOnce="true" damping="0.2" cascade="true">

                        {isDesktop ? (
                            this.finalList
                        ) : (
                                this.shortList
                            )}
                    </Fade>

                </div>
            </div>
        )
    }
}
