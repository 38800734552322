/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
/* components */
import Header from '../header';
import Partenariat from '../partenariat';
import Footer from '../footer';
import Navbar from '../navbar';
import Services from '../services';
import References from '../references';
import ContactModal from '../contact';
import Equipe from '../equipe';
// eslint-disable-next-line no-unused-vars
import { Link, animateScroll as scroll } from "react-scroll";

/* styles */
import './app.scss';
import Presentation from '../presentation/presentation';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      toggleScrollTopButton: false,
    }
  }

  /** Toggle de la modal contact */
  showContactModal = e => {
    this.setState({
      showModal: !this.state.showModal
    });
  }
  /** Listener qui permet de capter le scroll de l'utilisateur */
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  /** Fonction appelée au scroll, affiche le bouton scroll to top */
  handleScroll = () => {
    if (window.pageYOffset > 250) {
      return this.setState({ toggleScrollTopButton: true });
    }
    return this.setState({ toggleScrollTopButton: false });
  }
  render() {
    return (
      <div className="App">
        <Navbar />

        <div className="global-container">
          <Header />
          {/* Contact modal */}
          <ContactModal onClose={this.showContactModal} show={this.state.showModal} />
          <ul className="main-content-container-list">
            <li className="block">
              <Presentation onContact={this.showContactModal} />
            </li>
            <li className="block">
              <Services />
            </li>
            <li className="block">
              <Equipe />
            </li>
            <li className="block">
              <Partenariat />
            </li>
            <li className="block">
              <References />
            </li>
          </ul>
          <Footer onContact={this.showContactModal} />
          {/* Partie boutons affichés en bas à droite de l'écran sous forme de colonne */}
          <div className="flex-container column-flex layer-button-container">
            <Link
              to="intro"
              smooth={true}
              duration={900}
              id="scroll-to-top-button"
              className={"layer-button " + (this.state.toggleScrollTopButton === true ? "displayed" : "hidden")}>
              <i className="fa fa-arrow-up"></i>
            </Link>
            <a onClick={e => { this.showContactModal() }} className="contact-button layer-button">
              <i className={"fa fa-" + (this.state.showModal === false ? "comments" : "times")}></i>
            </a>
          </div>
        </div>


      </div >
    )
  };
}