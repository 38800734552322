import React, { Component } from 'react'
import Descriptioncard from '../bloc-components/description-card';
import Header from '../bloc-components/header-component';
/** styles */
import './services.scss';
/** fake API */
import placeholderDatas from './placeholder.json';

export default class Services extends Component {
    constructor(props) {
        super();
        /** requête API pour les services */
        this.serviceDatas = placeholderDatas;
        this.state = {
            servicesList: Object.values(this.serviceDatas)
        }
        this.finalServiceList = this.state.servicesList.map((serviceItem) =>
            <Descriptioncard
                key={serviceItem.key}
                color="white-colored"
                title={serviceItem.title}
                details={serviceItem.details}
                logo={serviceItem.logo}
                serviceItem={serviceItem} />)
    }
    render() {
        return (
            <div id="services" className="services-block">
                <Header theme="dark" title="Services" subtitle="Notre expertise" />
                    <Descriptioncard
                        key={this.serviceDatas[1].key}
                        color="white-colored"
                        title={this.serviceDatas[1].title}
                        details={this.serviceDatas[1].details}
                        logo={this.serviceDatas[1].logo}
                        serviceItem={this.serviceDatas[1]} 
                    />
                    <ul className="flex-container">
                    <li className="sub-service-container">
                        <Descriptioncard
                        key={this.serviceDatas[2].key}
                        color="white-colored"
                        title={this.serviceDatas[2].title}
                        details={this.serviceDatas[2].details}
                        logo={this.serviceDatas[2].logo}
                        serviceItem={this.serviceDatas[2]} 
                        />
                    </li>
                    <li className="sub-service-container">
                        <Descriptioncard
                        key={this.serviceDatas[3].key}
                        color="white-colored"
                        title={this.serviceDatas[3].title}
                        details={this.serviceDatas[3].details}
                        logo={this.serviceDatas[3].logo}
                        serviceItem={this.serviceDatas[3]} 
                        />
                    </li>
                    <li className="sub-service-container">
                        <Descriptioncard
                        key={this.serviceDatas[4].key}
                        color="white-colored"
                        title={this.serviceDatas[4].title}
                        details={this.serviceDatas[4].details}
                        logo={this.serviceDatas[4].logo}
                        serviceItem={this.serviceDatas[4]} 
                        />
                    </li>
                    </ul>

            </div>
        )
    }
}
