/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-scroll';
import './header.scss';

export default class Header extends Component {
    render() {
        return (
            <div>
                <header id="intro">
                    <div className="header-container">
                        <div className="inner-container flex-container column-flex">
                            <div>
                                <h1 className="">Expertise Data Management</h1>
                            </div>
                            <div className="services-container flex-container row-flex">
                                <h3>
                                    <Link to="services" smooth={true} duration={300} offset={-70} >Consulting</Link>
                                    <Link to="services" smooth={true} duration={300} offset={-70} >Infogérance</Link>
                                    <Link to="services" smooth={true} duration={300} offset={-70} >Cloud</Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}
