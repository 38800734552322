/** API utils */
import { app } from '../../utils/axiosConfig';
/** React component */
import React, { Component } from 'react';
import { Fade } from 'react-awesome-reveal';
/** Module de validation de formulaire */
import { useForm } from "react-hook-form";
import "./contact.scss";

/**
* Envoie la requête API avec les données du form
*/
async function sendMailApiQuery(data) {
    let res = "";
    try {
        res = await app.post('sendmail', data);
    } catch (error) {
        return res = "error"
    }
    return await res.data;
}

export default class ContactModal extends Component {
    constructor() {
        super();
        this.Form.bind(this);
        this.state = {
            formStatus: "not-sent"
        }
    }

    Form = e => {
        const { register, handleSubmit, errors } = useForm();
        // gestion du submit en asynchrone
        const onSubmit = async data => {
            this.setState({ formStatus: "waiting" })
            // appel de l'api
            let res = await sendMailApiQuery(data);
            setTimeout(() => {
                if (res === "OK") return this.setState({ formStatus: "sent" });
                return this.setState({ formStatus: "problem" });
            }, 1500)

        };
        switch (this.state.formStatus) {
            case "waiting": return (<div className="loader"></div>);
            case "sent": return (<div><h3 className="reussite">Mail envoyé !</h3><p>Nous ferons en sorte de vous recontacter au plus vite.</p></div>);
            case "problem": return (<h3 className="echec">Un problème est survenu, merci de réessayer plus tard</h3>);
            default: return (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-block flex-container column-flex">
                        <label htmlFor="mail">Votre adresse mail :</label>
                        <input ref={register({ required: true })} type="email" name="mail" id="mail" placeholder="john@doe.com" />
                        {errors.mail && <span className="error-form">Nous avons besoin de votre adresse mail tel que 'nom@domaine.xx' afin de vous répondre.</span>}
                    </div>
                    <div className="form-block flex-container column-flex">
                        <label htmlFor="entity">Votre entreprise ou l'entité que vous représentez :</label>
                        <input ref={register} type="text" name="entity" id="entity" placeholder="Doe's Company" />

                    </div>
                    <div className="form-block flex-container column-flex">
                        <label htmlFor="message">Votre message :</label>
                        <textarea
                            ref={register({ required: true })}
                            type="textarea"
                            name="message"
                            id="message"
                            placeholder="Le contenu de votre message..."
                            rows="8"
                            cols="40"
                            maxLength="450"
                            minLength="10"
                        />
                        {errors.message && <span className="error-form">Votre message doit correspondre un minimum de caractères pour être valable.</span>}
                    </div>
                    <div className="flex-container row-flex form-block">
                        <button type="submit">Envoyer le message</button>
                        <span>Aucune donnée personnelle n’est conservée par notre site via ce formulaire</span>
                    </div>
                </form>
            )
        }
    }

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    }
    render() {
        if (!this.props.show) {
            return null
        }
        return (
            <div className="modal-container flex-container column-flex">
                <Fade>

                    <i onClick={e => { this.onClose(e) }} className="button-close fa fa-times"></i>
                    <div>
                        <h1>Nous contacter</h1>
                        <this.Form />
                    </div>
                </Fade>

            </div>
        )
    }
}