import React, { Component } from "react";
import "./footer.scss";

export default class Footer extends Component {
  constructor(props) {
    super();
    this.logo = require("../../images/logo-fnd-blanc.png");
    /** TODO Créer une entité addresse récupérable via API */
    this.address = {
      avenue: "7 rue des Augustins",
      city: "59800 - Lille",
      tel: "+33 374 097 007",
      mail: "contact@neodt.fr",
    };
  }
  onContact = (e) => {
    this.props.onContact && this.props.onContact(e);
  };
  render() {
    return (
      <footer className="flex-container column-flex">
        <div className="flex-container row-flex">
          <div className="brand-container flex-container row-flex">
            <img src={this.logo} alt="Logo neo d t" />
            <div className="brand-title">
              <p>NeoDT</p>
              <p>Les experts du data management.</p>
            </div>
          </div>
          <div className="social-container flex-container row-flex">
            <a
              title="Liens vers notre Twitter"
              className="button--red"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/neodt_sas"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              title="Liens vers notre LinkedIn"
              className="button--red"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/neodt/?originalSubdomain=fr"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <span
              onClick={(e) => {
                this.onContact(e);
              }}
              title="Liens vers notre formulaire de contact"
              className="button--red"
              rel="noopener noreferrer"
            >
              <i className="fa fa-comments"></i>
            </span>
          </div>
        </div>
        <div className="flex-container row-flex contact-row">
          <span
            onClick={(e) => {
              this.onContact(e);
            }}
            title="Liens vers notre formulaire de contact"
            className="button--red"
            rel="noopener noreferrer"
          >
            Contactez-nous
          </span>
        </div>
        <div className="flex-container row-flex">
          <div className="address-container">
            <p>NeoDT FRANCE</p>
            <p> {this.address.avenue} </p>
            <p> {this.address.city} </p>
            <p> {this.address.tel} </p>
            <p> {this.address.mail} </p>
          </div>
          <div className="laws-container">
            <p>
              Icones réalisées par{" "}
              <a href="https://www.flaticon.com/authors/becris" title="Becris">
                Becris
              </a>{" "}
              de{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                {" "}
                www.flaticon.com
              </a>{" "}
              <br />
              &copy; 2020 NeoDT. Tous droits réservés.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
