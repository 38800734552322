import React, { Component } from 'react';
import './flipcard.scss';
/**
 * Carte flip au survol avec image et double description
 * @param {string} title - Titre principal
 * @param {string} description - Description de la carte
 * @param {string} details - Description dos de la carte
 * @param {string} link - Lien au dos
 * @param {string} logo - logo à afficher
 */
export default class Flipcard extends Component {
    constructor(props) {
        super();
        this.title = props.title;
        this.description = props.description;
        this.details = props.details;
        this.link = props.link;
        if (props.logo) {
            this.logo = require('../../../images' + props.logo + '.png');
        }
        this.state = {
            isFlipped: false
        };
    }

    toggleFlipCard() {
        this.setState({
            isFlipped: !this.state.isFlipped
        })
    }

    render() {
        return (
            <div className="card-container flex-container column-flex" onClick={(e) => this.toggleFlipCard(e)}>
                <div 
                    className={"card " 
                        +(this.state.isFlipped === true ? " is-flipped " : " not-flipped")} 
                    >
                    <div className="card-face front">
                        <div className="flex-container column-flex flip-card-image-container">
                            <img
                                src={this.logo}
                                alt={"Logo de " + this.title} />
                        </div>
                        <div className="flex-container column-flex flip-card-description-container">
                            <h1>{this.title}</h1>
                            <p>{this.description} </p>
                        </div>
                        <span>Survoler pour en savoir plus <i className="fa fa-reply fa-rotate-90"></i></span>
                    </div>
                    <div className="card-face back">
                        <div className="flex-container column-flex flip-card-image-container">
                            <img
                                src={this.logo}
                                alt={"Logo de " + this.title} />
                        </div>
                        <div className="flex-container column-flex flip-card-description-container">
                            <p>{this.details} </p>
                        </div>
                        <a href={this.link} title={"Redirection vers le site de " + this.title} target="_blank" rel="noopener noreferrer">Visiter leur site web</a>
                    </div>
                </div>
            </div>
        )
    }
}
