/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
/* useful images */
import logo from '../../images/logo.png';
/* styles */
import './navbar.scss';
/* smooth scroll */
// eslint-disable-next-line no-unused-vars
import { Link, animateScroll as scroll, Events } from "react-scroll";


export default class Navbar extends Component {
    constructor(props) {
        super();
        this.navbar = React.createRef();
        this.state = {
            className: "",
            toggleResponsiveMenu: "navbar",
        }
    }

    /** Event listener pour le changement d'apparence de la navbar */
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        Events.scrollEvent.register('begin', e => {
            this.state.toggleResponsiveMenu === "navbar responsive" &&
                this.toggleResponsiveMenu()
        });
    }

    /** fonction lancée par l'event listener, ajout de la classe white à navbar */
    handleScroll = () => {
        if (window.pageYOffset > 100) {
            if (!this.state.className) {
                this.setState({ className: " white " });
            }
        } else {
            if (this.state.className) {
                this.setState({ className: "" });
            }
        }
    }

    /** affiche le menu responsive (full screen et scroll bloqué) */
    toggleResponsiveMenu() {
        if (this.state.toggleResponsiveMenu === "navbar") {
            return this.setState({ toggleResponsiveMenu: "navbar responsive" });
        }
        return this.setState({ toggleResponsiveMenu: "navbar" });
    }

    render() {
        return (
            <nav id="navbar" className=
                {(this.state.className
                    + this.state.toggleResponsiveMenu)} ref={this.navbar}>
                <Link
                    spy={true}
                    offset={-70}
                    smooth={true}
                    duration={500}
                    to="intro"
                    className="navbar-brand">
                    <img src={logo} data-active-url={logo} alt="logo de neodt" />
                </Link>
                <Link spy={true} offset={-120} smooth={true} duration={500} to="presentation" className="navbar-link">Qui sommes-nous</Link>
                <Link spy={true} offset={-80} smooth={true} duration={500} to="services" className="navbar-link">Services</Link>
                <Link spy={true} offset={-110} smooth={true} duration={500} to="equipe" className="navbar-link">Notre équipe</Link>
                <Link spy={true} offset={-110} smooth={true} duration={500} to="partenariat" className="navbar-link">Partenariats</Link>
                <Link spy={true} offset={-110} smooth={true} duration={500} to="references" className="navbar-link">Références</Link>
                <a href="#navbar" onClick={this.toggleResponsiveMenu.bind(this)} className="icon">
                    <i className="fa fa-bars"></i>
                </a>
            </nav>
        )
    }
};