import React, { Component } from 'react';
import Header from '../bloc-components/header-component';
import './presentation.scss';
import { Fade } from "react-awesome-reveal";
/* smooth scroll */
// eslint-disable-next-line no-unused-vars
import { Link, animateScroll as scroll } from "react-scroll";

/**
 * Componenent block présentation de NeoDT
 * @param {string} presentationText - Texte de présentation de la boite.
 */
export default class Presentation extends Component {
    constructor(props) {
        super();
        this.map = require('../../images/france-map.png');
        this.presentationText = "NeoDT est une société de conseil fondée en 2015 à Villeneuve-d'Ascq (Hauts-de-France). Notre spécialité : le conseil dans la <b>gestion de données (Data Management)</b> et les infrastructures <b>Cloud</b>.";
    }
    onContact = e => {
        this.props.onContact && this.props.onContact(e);
    }
    render() {
        return (

            <div id="presentation" className="presentation-container flex-container column-flex">
                <Header title="Présentation" subtitle="Qui sommes-nous ?" />
                <p dangerouslySetInnerHTML={{ __html: this.presentationText }}></p>
                <div className="presentation-illustrations flex-container row-flex">
                    <div className="france-map">
                        <Fade fraction="0.45" triggerOnce="true" cascade="true" damping="0.5">
                            <img src={this.map} alt="Carte de la france" />
                            <p>Basée à Lille avec une présence nationale.</p>
                            <i className="fa fa-map-marker-alt"></i>
                        </Fade>
                    </div>
                    <div className="presentation-details flex-container column-flex">
                        <p className="flex-container">
                            NeoDT, c'est un réseau de 40 experts des bases de données et des infrastructures
                        </p>
                        <div className="button-fading-container flex-container row-flex">
                            <Link offset={-70} to="partenariat" smooth={true} duration={500} className="button--white">Nos partenaires</Link>
                            <a className="button--red linkedin-presentation-button" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/neodt/?originalSubdomain=fr">Suivez-nous sur <i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
