import React, { Component } from 'react';
import "./subcard.scss";

/**
 * Subcard dépliable des descriptioncard (component)
 * @param {string} color - Couleur héritée du parent pour colorer la subcard
 */
export default class Subcard extends Component {
    render() {
        return (
                <div className={"sub-card " + this.props.color}>
                    <h2>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                </div>
        )
    }
}
